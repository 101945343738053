import React, { useState, useEffect } from "react"

const SubmitButton = () => {
  const [submit, setSubmit] = useState(0)

  const handleSubmit = e => {
    e.preventDefault()
    if (submit === 0) {
      setSubmit(1)
    }
  }

  useEffect(() => {
    if (submit === 1) {
      const request = setTimeout(() => {
        setSubmit(2)
      }, 3000)
      return () => clearTimeout(request)
    }
    if (submit === 2) {
      const request = setTimeout(() => {
        setSubmit(0)
      }, 1000)
      return () => clearTimeout(request)
    }
  }, [submit])

  const handleButton = () => {
    switch (submit) {
      case 0:
        return "Submit"

      case 1:
        return "...Submitting"

      case 2:
        return "✅ Complete"

      default:
        break
    }
  }

  return (
    <div className="text-center">
      <div
        role="button"
        tabIndex={0}
        onClick={handleSubmit}
        onKeyDown={handleSubmit}
        className={`inline-block flex-none bg-white bg-opacity-10 text-white text-lg leading-6 font-semibold py-3 px-12 border-white border-2 rounded-xl focus:outline-none transition-colors duration-200 ${
          submit > 0 ? "border-opacity-25 cursor-default" : null
        }`}
      >
        {handleButton()}
      </div>
    </div>
  )
}

export default SubmitButton
