import React from "react"
import Container from "../container"

const Article = ({ children }) => {
  return (
    <article className="prose pt-10 mx-auto">
      <Container>{children}</Container>
    </article>
  )
}

export default Article
