class MultiStep {
  constructor(form) {
    this.form = document.getElementById(form)
    this.action = this.form.action
    this.steps = this.form.children
    this.currentStep = 0
    this.progressBarType = true
    this.style = false
  }

  // Add event listener to elements in form
  events() {
    document.querySelector(".multi-step-outer").addEventListener("click", e => {
      if (e.target.matches("#Next")) {
        // Go to next step
        e.preventDefault()

        if (this.currentStep < this.steps.length && this.validate()) {
          this.currentStep++
          if (this.currentStep < this.steps.length) {
            this.showHide()
            this.progressBar()
            this.buttons()
          }
        }
      }

      if (e.target.matches("#Prev")) {
        // Go to previous step
        e.preventDefault()
        if (this.currentStep > 0) {
          this.currentStep--
          this.showHide()
          this.progressBar()
          this.buttons()
        }
      }

      if (e.target.matches("#Submit")) {
        if (this.validate()) {
          this.submit()
        }
      }
    })
  }

  showHide() {
    // Get all the direct child elements

    // Loop through child elements and hide them
    for (let i = 0; i < this.steps.length; i++) {
      if (i !== this.currentStep) {
        this.steps[i].style.display = "none"
      }

      if (i === this.currentStep) {
        //stepsArray[this.currentStep].classList.remove("hidden")
        this.steps[this.currentStep].style.display = "block"
      }

      // Display the element that is equal to the current step
    }
  }

  // Add a progress bar to the form
  progressBar() {
    // Create status progress bar
    let percentage = (100 / this.steps.length) * (this.currentStep + 1)
    let stepText = this.currentStep + 1
    if (this.progressBarType) {
      const progressBarFill = document.getElementById("ProgressBarFill")
      progressBarFill.style.width = `${percentage}%`
      const progressBarText = document.getElementById("ProgressBarText")
      progressBarText.innerText = `Step ${stepText} of ${this.steps.length}`
    }
  }

  buttons() {
    const navButtons = document.querySelector(".nav-buttons")
    if (this.currentStep === 0) {
      navButtons.innerHTML = `<button id="Next">Continue</button>`
    }
    if (this.currentStep > 0 && this.currentStep < this.steps.length) {
      navButtons.innerHTML = `<button id="Prev">Previous</button><button id="Next">Continue</button>`
    }
    if (this.currentStep === this.steps.length - 1) {
      navButtons.innerHTML = `<button id="Prev">Previous</button><button id="Submit">Submit</button>`
    }
  }
  // https://www.learnwithjason.dev/blog/get-form-values-as-json
  // Submit form
  submit() {
    const data = new FormData(document.querySelector("form"))
    const value = Object.fromEntries(data.entries())

    fetch(this.action, {
      method: "POST",
      body: JSON.stringify(value),
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
    })
      .then(response => response.json())
      .then(json => this.confirmation(json))
  }

  confirmation(data) {
    const elem = document.querySelector(".multi-step-outer")
    elem.classList.add("confirmation")
    let confirmationData = ""
    for (let key in data) {
      confirmationData += "<p>" + key + ": " + data[key] + "</p>"
    }
    elem.innerHTML = `<h3>Confirmation</h3> ${confirmationData}`
  }

  // Create a postcode lookup from an input
  postcode() {
    console.log("postcode")
  }

  // Validate inputs at each step
  validate() {
    let x,
      y,
      i,
      a,
      valid = true
    x = this.steps
    y = x[this.currentStep].getElementsByTagName("input")
    a = x[this.currentStep].querySelector(".error")
    //@TO_DO Get other input types in the step. Select, radio, check
    for (i = 0; i < 1; i++) {
      // If field is empty
      if (y[i].checkValidity() === false) {
        // Add 'invalid' class to field
        y[i].className += " invalid"
        a.style.display = "block"
        // Set current valid status to false
        valid = false
      } else {
        y[i].classList.remove("invalid")
      }
    }
    return valid
  }

  create() {
    // Create a wrapper to contain the form
    const wrapper = document.createElement("div")
    wrapper.classList.add("multi-step-outer")
    this.form.parentNode.appendChild(wrapper)
    wrapper.appendChild(this.form)

    // Hide errors
    const errors = document
      .querySelector(".multi-step-outer")
      .getElementsByClassName("error")
    for (let i = 0; i < errors.length; i++) {
      errors[i].style.display = "none"
    }

    // Create a progress bar wrapper
    const progressBarWrapper = document.createElement("div")
    progressBarWrapper.classList.add("progress-bar")
    wrapper.insertBefore(progressBarWrapper, wrapper.childNodes[0])

    if (this.progressBarType) {
      // Create a step counter progress bar
      // @TO_DO Do this in a single step
      const progressBar = document.createElement("div")
      progressBar.className += "ProgressBarWrap"
      progressBar.innerHTML = `<div id="ProgressBarFill"></div>`

      const progressBarText = document.createElement("div")
      progressBarText.innerHTML = `<p id="ProgressBarText" >Get started</p>`

      progressBarWrapper.insertBefore(
        progressBar,
        progressBarWrapper.childNodes[0]
      )
      progressBarWrapper.insertBefore(
        progressBarText,
        progressBarWrapper.childNodes[0]
      )
    }

    // if (!this.progressBarType) {
    //   // Create a step navigation progress bar
    //   for (let i = 0; i < this.steps.length; i++) {
    //     const element = this.steps[i]
    //   }
    // }

    // @TO-DO Create a for loop to add the step numbers to the progress bar

    // Create navigation
    const navButtons = document.createElement("div")
    navButtons.classList.add("nav-buttons")
    wrapper.insertBefore(navButtons, wrapper.childNodes[2])
    this.buttons()

    // If style options selected style form
    if (this.style) {
      const style = document.createElement("style")
      style.innerHTML = `
			body {
				background-color: black;
			}
			
		`
      document.head.appendChild(style)
    }

    this.events()
    this.showHide()
    this.progressBar()
  }
}

export default MultiStep

// @TO-DO Progress bar at first step to say "Get started", last step to say complete
// @TO-DO Add required and patterns to HTML form and use that in validate method instead of checking an empty string
