import React, { useEffect } from "react"
import MultiStep from "../../scripts/mulit-step-form"

const MultiStepForm = () => {
  useEffect(() => {
    // const script = document.createElement("script")
    // script.src = "../../scripts/mulit-step-form.js"
    // script.async = true
    // document.head.appendChild(script)
    const exampleForm = new MultiStep("register")
    exampleForm.create()
    return () => {
      // document.head.removeChild(script)
    }
  }, [])

  return (
    <div className="max-w-lg mx-auto">
      <form
        id="register"
        action="https://jsonplaceholder.typicode.com/posts"
        method="POST"
      >
        <div>
          <label htmlFor="firstname">First name</label>
          <input
            type="text"
            name="firstname"
            id="firstname"
            autocomplete="off"
            placeholder="Enter your first name"
            required
          />
          <p className="error text-red-500 text-right">
            <small>First name is required</small>
          </p>
        </div>
        <div>
          <label htmlFor="lastname">Last name</label>
          <input
            type="text"
            name="lastname"
            id="lastname"
            autocomplete="off"
            minlength="5"
            required
          />
          <p className="error text-red-500 text-right">
            <small>Last name is required</small>
          </p>
        </div>
        <div>
          <label htmlFor="email">Email address</label>
          <input
            type="text"
            name="email"
            id="email"
            autocomplete="off"
            pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
            required
          />
          <p className="error text-red-500 text-right">
            <small>Please enter a valid email address</small>
          </p>
        </div>
      </form>
    </div>
  )
}

export default MultiStepForm
