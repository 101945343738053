import React from "react"
import { motion } from "framer-motion"
import Container from "../container"

const easing = [0.6, -0.5, 0.01, 0.99]

const fadeInUp = {
  initial: {
    y: 60,
    opacity: 0,
  },
  animate: {
    y: 0,
    opacity: 1,
    transition: {
      duration: 0.6,
      ease: easing,
    },
  },
}

const stagger = {
  animate: {
    transition: {
      staggerChildren: 0.3,
    },
  },
}

const hero = props => {
  return (
    <motion.div exit={{ opacity: 0 }} initial="initial" animate="animate">
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        className="hero py-48 md:py-52"
        style={{ backgroundColor: `${props.featureColor}` }}
      >
        <Container>
          <motion.div variants={stagger}>
            <motion.div variants={fadeInUp}>
              <h1 className="text-white text-4xl md:text-5xl font-bold text-center mb-16">
                {props.title}
              </h1>
            </motion.div>

            <motion.div variants={fadeInUp}>{props.children}</motion.div>
          </motion.div>
        </Container>
      </motion.div>
    </motion.div>
  )
}

export default hero
